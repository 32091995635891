<template>
<v-container>
  <h1 class="title grey--text">日程表</h1>

  <!-- 该项目全部班级活动总览 -->
  <v-card style="height: 100%;" class="pa-4 my-4">
    <v-subheader>
      日历总览
    </v-subheader>
    <v-row>
      <v-col>
      <y-lesson-calendar :key="calendarKey" :programIds="programIds" :organizationIds="organizationIds" :tutorIds="tutorIds" :classIds="classIds"/>
      </v-col>
    </v-row>
  </v-card>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</v-container>
</template>

<script>
import YLessonCalendar from '@/components/YLessonCalendar'
import OrganizationService from '../services/OrganizationService'

export default {
  components: {
    YLessonCalendar
  },
  data () {
    return {
      tutorIds: [],
      programIds: [],
      organizationIds: [],
      classIds: [],
      calendarKey: 0 // 排课表Key，改变该数值会触发组件更新
    }
  },
  watch: {
    async $route (to, from) {
      await this.loadQuery()
      this.forceRerenderCalendar()
    }
  },
  computed: {
  },
  async created () {
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)
    await this.loadQuery()
    this.forceRerenderCalendar()
    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },

  beforeDestroy () {
  },

  methods: {
    async loadQuery () {
      this.tutorIds = []
      this.programIds = []
      this.organizationIds = []
      this.classIds = []
      if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId || this.$route.query.classId || this.$route.query.managerId) { // 所接受的路径参数
        console.log('检测到路径参数变化, 更新日程')
        if (this.$route.query.organizationId) this.organizationIds = [this.$route.query.organizationId]
        if (this.$route.query.tutorId) this.tutorIds = [this.$route.query.tutorId]
        if (this.$route.query.programId) this.programIds = [this.$route.query.programId]
        if (this.$route.query.classId) this.classIds = [this.$route.query.classId]
        if (this.$route.query.managerId) {
          const managerIds = [this.$route.query.managerId]
          const res = await OrganizationService.getOrganizationsInfo({ managerIds })
          let organizationsInfo = res.data
          console.log(`manager organizations:`)
          console.log(organizationsInfo)
          this.organizationIds = organizationsInfo.map(organization => organization.organizationId)
        }
      }
    },
    cancel () {
      // route
      this.$router.go(-1)
    },
    forceRerenderCalendar () {
      this.calendarKey += 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
